.lc-board {
  background: #f1f5f5;
  border-radius: 10px;
  box-shadow: 0 6px 35px 0 $shadow-xs;
  margin: 20px;
  width: 400px;
  z-index: 401; // Just above leaflet's layers
  margin-top: $header-height + 20px;

  &.lc-no-board {
    display: none;
  }

  @include desktop {
    position: absolute;
    top: 0;
    max-height: calc(100% - 40px); // 2x board margin !
  }

  a {
    color: $primary;
    text-decoration: none;

    &.lc-login {
      color: white;
    }
  }

  &.lc-no-module {
    overflow-y: auto;
  }

  &.lc-large {
    width: 440px;
  }

  &.lc-small {
    width: 320px;

    @include font-small;

    .lc-menu-item-description {
      padding: 5px 10px 0 0 !important;
    }

    .lc-group-mode-logo {
      width: 35px;
    }

    .lc-with-direction {
      width: 40px;
    }

    .lc-group {
      padding: 15px 20px 5px 10px;
    }
  }

  .lc-board-header {
    background: #f1f5f5;
    padding: 15px;
    border-radius: 10px 10px 0 0;
    display: flex;
    height: 70px;

    .lc-back {
      border-top-left-radius: 15px;
      background: white;
      border-radius: 5px 0 0 5px;
      border: 0;
      border-right: 1px solid #e3e4e4;
      cursor: pointer;
      padding: 0 15px;
      margin: 0;

      img {
        height: 28px;
      }
    }

    .lc-board-title {
      align-items: center;
      display: inline-flex;
      padding-left: 20px;
      color: $text;
      font-weight: 500;
      background: white;
      flex: 1;
      border-radius: 0 5px 5px 0;
      margin: 0;
      font-size: 1em;
      white-space: pre;

      &.lc-with-subtitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: initial;

        .lc-board-title-uppercase {
          text-transform: uppercase;
          white-space: pre-wrap;
          padding-right: 5px;
        }

        .lc-board-title-town,
        .lc-board-title-type-partner,
        .lc-board-title-subtitle {
          text-transform: none;
          @include font-x-small;
          color: #888;
        }
      }

      &.lc-board-title-lock {
        border-radius: 5px;
      }

      .lc-images {
        height: 32px;
      }
    }
  }

  // .lc-scrolling {
  //   @include mobile {
  //     overflow-y: scroll;
  //     padding-bottom: 20px;
  //   }
  // }

  .lc-content,
  .lc-sub-content {
    background: #f1f5f5;
    border-radius: 10px;
    flex: 1;
    position: relative;

    // -ms-overflow-style: none;
    // scrollbar-width: none;

    &::-webkit-scrollbar {
      //width: 0 !important;
    }

    &.lc-main {
      background: transparent;
      margin: 15px;
      box-shadow: 0 5px 22px 0 rgba(112, 113, 115, 0.25);
      border-radius: 5px;
      overflow-y: initial;
    }

    .lc-around-info {
      padding: 10px;
      margin: 15px 0px 0px 0px !important;
      @include font-medium;
    }

    .lc-fallback {
      padding: 10px 20px 20px;
    }

    .lc-menu-item {
      background: white;
      align-items: center;
      border-bottom: 1px solid #e3e4e4;
      color: #333333;
      display: flex;
      flex: 1;
      padding: 20px 10px;
      text-decoration: none;

      &:hover:not(.lc-menu-item-title) {
        background: $hover;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &.lc-ending-menu {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .lc-images {
        height: 25px;
      }

      .lc-menu-item-content {
        flex: 1;
        padding-left: 10px;

        .lc-menu-item-description {
          padding: 5px 20px 0 0;
          font-weight: 300;
        }
      }

      .lc-menu-item-arrow {
        margin: 0 10px;
      }
    }

    .lc-menu-item-page {
      background: white;
      display: flex;
      flex: 1;
      font-size: 0.85em;
      justify-content: flex-end;
      padding: 10px 20px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        background: $hover;
      }

      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .lc-menu-title {
      color: #58585a;
      font-size: 1em;
      font-weight: 500;
      text-transform: none;
    }

    .lc-sub-module {
      display: flex;
      justify-content: space-around;
      text-align: center;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .lc-menu-item {
        justify-content: flex-start;
        flex-direction: column;
        font-size: 0.875em;
        padding: 10px;

        &:first-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-bottom-right-radius: 5px;
        }

        &:not(:last-child) {
          border-right: 1px solid #e3e4e4;
        }
      }

      .lc-images {
        width: 25px;
      }
    }

    .lc-react-tabs {
      margin: 0 15px 15px;
      background: white;
      border-radius: 5px;

      @include desktop {
        @include font-medium;
      }

      .lc-tabs-shared-text {
        padding: 0 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);

        h4 {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      &.lc-without-margin {
        margin: 0;
      }

      .lc-menu-title {
        color: $text;
        text-transform: none;
      }

      .lc-tab-list {
        .lc-tab {
          padding: 15px;
          text-transform: none;
        }
      }
    }

    &.lc-page {
      padding: 15px;

      .lc-title {
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: 1em;
        margin-top: 2em;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .lc-sub-content {
    margin: 0 15px 15px !important;

    &.lc-main {
      .lc-menu-item {
        .lc-images {
          height: 35px !important;
        }

        &:first-child {
          border-radius: 5px;
        }
      }
    }
  }

  @include desktop {
    transform: none !important;
  }

  @include mobile {
    margin: 0;
    width: 100% !important;
    overflow-x: hidden;
    min-height: 40vh;
    border-radius: 0;
    box-shadow: none;

    .lc-board-header {
      height: 60px;
      padding: 0 10px 10px !important;
      position: sticky;
      top: 0;

      .lc-board-title {
        padding-left: 15px;

        .lc-images {
          height: 28px;
          width: initial;
        }
      }
    }

    .lc-content {
      flex: initial;
      border-radius: 0;
      margin: 10px auto;

      &.lc-main {
        margin: 10px;
      }

      .lc-around-info {
        padding: 10px;
        margin: 10px 0px 0px 0px !important;
        @include font-small;
      }

      .lc-react-tabs {
        margin: 0 10px 10px;

        &.lc-without-margin {
          margin: 0;
        }

        .lc-tab {
          padding: 10px !important;
        }

        .lc-group {
          padding: 10px 10px 0;
        }
      }

      .lc-form {
        .lc-prefs {
          margin: 10px 0 0;

          .lc-route-calculation-modes,
          .lc-around-radius-times {
            margin: 10px 2px 0;
            grid-gap: 10px;
          }
        }

        .lc-buttons {
          flex-direction: column;
          align-items: initial;

          .lc-collapse {
            width: initial !important;
            flex: 1;
          }
        }

        .lc-inputs {
          .lc-input {
            padding: 10px;
          }
        }

        .lc-autocomplete {
          left: 15px;
          right: 15px;
          margin-top: 35px;
          max-height: 250px;
          width: auto;

          &.lc-offsetRoute {
            width: 80% !important;
          }
        }
      }

      .lc-menu-item {
        padding: 15px;
      }

      .lc-go-to-route {
        padding: 0;
        font-size: 0.85em;

        img {
          width: 20px;
        }
      }
    }

    .lc-sub-content {
      flex: initial;

      &.lc-main {
        margin: 10px !important;
      }
    }

    .lc-elevation {
      margin: 0 10px;

      &.lc-without-margin {
        margin: 0;
      }

      .lc-lines {
        grid-template-columns: repeat($grid-size-mobile, 1fr);
      }

      .lc-group {
        padding: 10px;

        .lc-group-name {
          margin-bottom: 0;
        }

        .lc-group-offset-bottom {
          margin-bottom: 0;
          margin-top: 10px;
        }
      }
    }

    .lc-form {
      padding: 0 10px 10px;
    }

    .lc-journeys {
      padding: 0 10px 0;

      .lc-journey {
        margin: 10px 0 !important;

        .lc-journey-time {
          padding: 0 10px !important;
        }
      }

      .lc-time {
        white-space: nowrap;
        padding: 6px !important;

        span {
          flex-direction: column;
          padding: 0 5px;

          span {
            padding: 0 !important;
          }
        }
      }

      .lc-soft {
        .lc-time {
          padding: 0 !important;
        }
      }

      .lc-transport {
        margin-bottom: 0;

        .lc-leave {
          padding: 5px 15px 5px 5px;
        }
      }
    }

    .lc-roadmap {
      padding: 20px 10px 10px !important;

      .lc-section-part-top,
      .lc-section-part-bottom,
      .lc-section-part-content {
        grid-template-columns: 45px 40px 1fr;

        .lc-section-part-bull {
          left: 12px;
        }

        .lc-section-part-details-stops-bull {
          left: -25px !important;
        }

        .lc-root-icon {
          margin-left: 85px !important;
        }
      }
    }
  }

  @include small-mobile {
    .lc-content,
    .lc-sub-content {
      .lc-menu-item {
        padding: 10px !important;
      }

      .lc-board-header {
        height: 45px;

        .lc-back {
          img {
            height: 20px;
          }
        }

        .lc-board-title {
          @include font-small;
        }
      }

      .lc-menu-title {
        @include font-small;
      }

      .lc-form {
        display: block !important;
      }

      .lc-group-name {
        .lc-mode {
          font-size: 1em !important;
        }
      }

      .lc-radius-value,
      .lc-route-calculation-mode {
        font-size: 0.8em;

        img {
          height: 25px;
        }
      }

      .lc-stop {
        .lc-seeTimetable {
          font-size: 0.75em !important;
        }
      }

      .lc-timetable-stop {
        font-size: 0.9em !important;
      }

      .lc-timetable {
        font-size: 0.9em !important;
      }

      .lc-lines {
        grid-template-columns: repeat($grid-size-small-mobile, 1fr);

        &.lc-line-with-direction {
          grid-template-columns: repeat(1, 1fr);

          .lc-attribute-line {
            display: flex;
            align-items: center;

            .lc-line {
              padding: 5px 5px;
              width: 50px;

              &.lc-code {
                @include font-small;
              }

              &.lc-mode {
                @include font-xx-small;
              }
            }

            .lc-name {
              padding-left: 10px;
              text-align: left;
              font-size: 0.875em;
            }
          }
        }
      }

      .lc-journeys {
        .lc-soft-items {
          .lc-softInfos {
            font-size: 0.9em;
            padding-left: 2px !important;

            .lc-time {
              font-size: 1em;
            }
          }
        }

        .lc-transport {
          .lc-transport-title {
            font-size: 1em;
          }

          .lc-journey {
            font-size: 0.9em;
          }
        }

        .lc-leave {
          padding: 4px 10px 4px 0 !important;
        }
      }

      .lc-roadmap {
        padding: 20px 10px;
        font-size: 0.9em;
      }
    }

    .lc-header {
      height: 50px;

      .lc-back {
        img {
          height: 40px;
        }
      }
    }
  }
}

.lc-thematic-board-text {
  padding: 15px;

  @include font-small;
}

.lc-elevation {
  margin: 0 15px 15px;
  background: white;
  border-radius: 5px;

  &.lc-without-margin {
    margin: 0;
  }
}

.lc-scroll {
  overflow-y: auto;

  // -ms-overflow-style: none;
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   width: 0 !important;
  // }
}

.lc-print {
  background: url(assetsPath("/assets/images/print.svg")) no-repeat center;
  height: 15px;
  width: 15px;
  cursor: pointer;
  z-index: 999;
}

.lc-print-roadmap {
  position: absolute;
  top: 35px;
  left: 345px;
  padding: 5px;
  background: url(assetsPath("/assets/images/print.svg")) no-repeat center;
  height: 15px;
  width: 15px;
  cursor: pointer;
  z-index: 99999;

  @include mobile {
    top: 18px;
    left: initial;
    right: 60px;
  }

  @include small-mobile {
    top: 12px;
    right: 50px;
    padding: 3px;
  }

  &.lc-without-share {
    left: 385px;

    @include mobile {
      right: 25px !important;
    }

    @include small-mobile {
      right: 20px !important;
    }
  }

  &.lc-with-header {
    @include mobile {
      left: initial;
      right: 60px;
    }

    @include small-mobile {
      left: initial;
      right: 50px;
      padding: 3px;
    }
  }

  #lc-print {
    opacity: 1;
    box-shadow: 0 0 10px $shadow-xs;
  }

  .lc-print-tooltip {
    padding: 10px 5px;
    border-radius: 8px;
    border: 1px solid $border-xs;
    min-width: 200px;

    label {
      cursor: pointer;
      display: flex;
      align-items: center;

      input {
        margin-right: 5px;
      }
    }

    button {
      margin: 5px 8px 0;
    }
  }
}

.lc-share {
  position: absolute;
  top: 35px;
  left: 385px;
  padding: 5px;
  background: url(assetsPath("/assets/images/share.svg")) no-repeat center;
  height: 15px;
  width: 15px;
  cursor: pointer;
  z-index: 99999;

  @include mobile {
    top: 18px;
    left: initial;
    right: 25px;
  }

  @include small-mobile {
    top: 12px;
    right: 20px;
    padding: 3px;
  }

  &.lc-with-header {
    @include mobile {
      left: initial;
      right: 25px;
    }

    @include small-mobile {
      left: initial;
      right: 25px;
      padding: 3px;
    }
  }
}

#lc-login {
  display: flex;
  flex-direction: column;
  padding: 12px;
  box-shadow: 0 0 10px $shadow-xs;
  opacity: 1;
}

#lc-share {
  display: flex;
  align-items: center;
  padding: 8px;
  box-shadow: 0 0 10px $shadow-xs;
  opacity: 1;

  a,
  div {
    padding: 0 5px;
  }

  .lc-pdf {
    img {
      width: 15px;
      padding: 3px 5px 0;
    }
  }

  .lc-clipboard {
    img {
      width: 20px;
      padding: 3px 0 0;
    }
  }

  .lc-messenger {
    img {
      width: 25px;
      padding: 3px 0 0;
    }
  }

  .lc-twitter {
    img {
      width: 20px;
      padding: 3px 0 0;
    }
  }

  .lc-whatsapp {
    img {
      width: 25px;
      padding: 3px 0 0;
    }
  }

  @include mobile {
    .lc-pdf {
      img {
        width: 20px;
        padding: 3px 5px 0;
      }
    }

    .lc-twitter {
      img {
        width: 25px;
        padding: 3px 5px 0;
      }
    }

    .lc-whatsapp {
      img {
        width: 30px;
        padding: 3px 0 0;
      }
    }
  }
}

::placeholder {
  color: #767676;
}

.lc-menu-item-arrow {
  background: url(assetsPath("/assets/images/menu-item-arrow.svg")) no-repeat center;
  background-size: contain;
  height: 20px;
  margin-left: 10px;
  width: 15px;
}

.lc-images {
  padding: 5px;
  width: 40px;
}

.lc-go-to-route {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: -20px;
  padding: 20px;
  font-style: italic;

  &:hover {
    background: $hover;
  }

  img {
    padding-right: 15px;
    width: 25px;
  }

  &.lc-around {
    margin: 0;
    padding: 0;

    img {
      padding: 0 10px;
    }
  }
}

.lc-form {
  padding: 0 15px 15px;

  form {
    width: 100%;
  }

  .lc-formNoBottom {
    padding: 15px;
  }

  .lc-inputs {
    border-radius: 5px;
    display: flex;
    background: white;

    .lc-input {
      border: none;
      border-radius: 5px;
      flex: 1;
      padding: 15px 20px;
      color: $text;
      font-family: $font;

      @include font-medium;

      @include mobile {
        @include font-small;
      }
    }

    .lc-input::placeholder {
      opacity: 0.6;
    }

    .lc-inputsText {
      display: flex;
      flex: 1;
      flex-direction: column;

      .lc-inputCalculation {
        align-items: center;
        display: flex;
        padding-left: 10px;
        position: relative;

        img {
          height: 32px;
          width: 25px;
        }

        .lc-input {
          padding: 15px 0 !important;
        }

        label {
          margin: 0 10px 0 10px;
          white-space: nowrap;

          @include font-medium;
        }

        .lc-autocompleteClear {
          margin: 0 0 0 10px;
        }
      }

      .lc-divider {
        background: $border-m;
        height: 1px;
        margin-left: 10px;
      }
    }

    .lc-swap {
      align-self: center;
      background: url(assetsPath("/assets/images/swap.svg")) no-repeat center;
      cursor: pointer;
      display: flex;
      height: 25px;
      margin: 10px;
      width: 25px;
    }
  }

  .lc-represents {
    display: flex;
    margin: 5px 0 5px 5px;
    padding: initial !important;

    .lc-radio {
      margin-right: 15px;
    }

    label.lc-radio-checked {
      font-weight: bold;
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      input {
        margin-right: 8px;
        margin-bottom: 5px;

        &:after {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          top: -2px;
          left: -3px;
          position: relative;
          background-color: #d1d3d1;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 2px solid white;
        }

        &:checked {
          &:after {
            background-color: $primary;
          }
        }
      }
    }
  }

  .lc-highlight {
    background: $hover;
  }

  .lc-alert-date-trip {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: $primary;
    margin: 15px 0 5px;

    @include font-x-small;

    img {
      margin-right: 5px;
    }
  }

  .lc-prefs {
    margin: 15px 0 0;
    display: flex;
    align-self: flex-start;
    flex-direction: column;

    @include font-small;

    .lc-buttons {
      display: inline-block;
    }

    .lc-collapse {
      background: white;
      padding: 8px 10px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      display: flex;
      min-width: 115px;
      position: relative;

      &:not(.lc-active) {
        &.lc-radius,
        &.lc-date,
        &.lc-modes,
        &.lc-profile {
          margin: 0 0 15px;

          @include mobile {
            margin: 0 0 10px;
          }
        }
      }

      span {
        padding: 0 0 0 5px;
        color: $primary;
        font-weight: 600;
      }

      .lc-menu-item-arrow {
        position: absolute;
        right: 10px;
        top: 6px;
        transition: all ease-in 150ms;

        &.lc-active {
          transform: rotate(90deg);
        }
      }
    }

    &.lc-around {
      margin: 0 0 -15px;
      .lc-collapse {
        padding: 8px 15px;
      }
    }

    .collapse-css-transition {
      .lc-expanding,
      .lc-expanded {
        padding: 10px 0;
      }

      .lc-collapsing {
        padding: 0;
        margin-top: -5px;
      }
    }
  }

  .lc-around-radius-times,
  .lc-route-calculation-modes,
  .lc-route-calculation-walking-speeds {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    margin: 5px 2px 10px 2px;

    label {
      align-items: flex-start;
    }

    .lc-radius-value,
    .lc-route-calculation-mode,
    .lc-route-calculation-walking-speed {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
      background: #e6e6e6;
      border-radius: 5px;
      color: #a5a6a7;
      font-weight: 400;
      cursor: pointer;
      text-align: center;

      @include font-small;

      &.lc-active {
        background: white;
        box-shadow: 0 0 5px $shadow-m;
      }

      img {
        height: 30px;
        padding: 5px 0;
      }
    }
  }

  .lc-pickers {
    display: flex;
    align-items: center;
    padding: 8px 0;
  }

  .lc-error,
  .lc-warning {
    margin: 0 0 15px;
    padding: 10px 0;

    @include font-small;

    @include mobile {
      margin: 0 0 10px;
    }
  }

  &.lc-with-error {
    .lc-error,
    .lc-warning {
      margin: 15px 0 0;

      @include mobile {
        margin: 10px 0 0;
      }
    }
  }
}

.lc-loading {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 10px 10px;

  &.lc-printing {
    padding: 0 15px 20px;
  }

  img {
    padding-bottom: 10px;
  }
}

.lc-button {
  background: $primary;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  padding: 15px;
  text-align: center;
  display: block;
}

.lc-formAutocomplete {
  display: flex;
  align-items: center;
  flex: 1;

  .lc-combobox {
    display: flex;
    flex: 1;
    position: relative;
  }

  .lc-autocompleteClear {
    cursor: pointer;
    margin-right: 10px;
    background: url(assetsPath("/assets/images/clear.svg")) no-repeat 50%;
    width: 20px;
    height: 20px;
    padding: 12px 0;
  }
}

.lc-autocomplete {
  background: #ffffff;
  box-shadow: 2px 4px 10px $shadow-xs;
  margin: 0;
  padding: 0;
  max-height: calc(calc(var(--vh, 1vh) * 90) - 220px);
  overflow-y: auto;
  position: absolute;
  top: 50px;
  width: 370px;
  z-index: 999;
  font-size: 0.875em;

  @include mobile {
    position: fixed;
  }

  .lc-autocompleteHistory {
    padding-bottom: 10px;

    ul {
      padding: 0;
      list-style-type: none;
    }
  }

  .lc-autocompleteHeader {
    color: #909194;
    padding: 15px 10px;
    border-top: 1px solid $border-xs;
  }

  &.lc-large {
    width: 410px;
  }

  &.lc-small {
    width: 290px;
  }

  &.lc-offsetRoute {
    right: 0;
    width: 365px;

    &.lc-offset-clear {
      right: -30px;
    }
  }

  .lc-autocomplete-line {
    padding: 5px 10px !important;
  }

  .lc-item {
    align-items: center;
    display: flex;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;

    & img {
      padding: 5px 7px;
      height: 20px !important;
      min-width: 40px !important;
      margin-right: 10px;
    }
  }

  .lc-itemHighlight {
    background: $hover;
    cursor: pointer;
  }
}

.lc-journeys-search-summary {
  cursor: pointer;
  margin: 0 0 15px;
  padding: 10px;
  display: flex;
  align-items: center;

  &.lc-in-journey {
    margin: 0 15px 15px !important;

    @include mobile {
      margin: 0 10px 10px !important;
    }
  }

  @include font-small;

  .lc-journeys-search-summary-date,
  .lc-journeys-search-summary-modes,
  .lc-journeys-search-summary-traveler-profil,
  .lc-journeys-search-summary-extend-area {
    color: $primary;
    font-weight: 500;

    span {
      color: $text;
      font-weight: normal;
    }
  }

  .lc-journeys-search-summary-text {
    flex: 1;

    & > div:not(:last-child) {
      margin: 0 0 3px;
    }
  }

  .lc-journeys-search-summary-edit {
    background: url(assetsPath("/assets/images/edit.svg")) no-repeat center;
    width: 25px;
    height: 25px;
    margin-right: 5px;
    cursor: pointer;
  }
}

.lc-journeys {
  overflow: auto;
  padding: 0 15px;

  .lc-journeys-no-result {
    margin: 0 0 15px;
    padding: 10px;
    color: $error;
    font-weight: bold;
  }

  .lc-soft {
    @include font-small;

    .lc-soft-items {
      display: flex;
      padding-top: 0;
      margin-bottom: 15px;

      .lc-softItem {
        cursor: pointer;
        display: flex;
        align-items: center;
        background: white;
        flex: 1;
        border-radius: 4px;
        max-width: 33%;
        margin: 0;
        box-shadow: 0 0 5px $shadow-xxs;

        &:nth-child(1) {
          margin-right: 5px;
        }

        &:nth-child(n + 2) {
          margin-left: 5px;
        }

        &:hover {
          background: white;
          box-shadow: 0 0 15px $shadow-xs;
        }

        .lc-root-icon {
          min-width: 20px;
          justify-content: center;
          border: 2px solid $border-xs;
          border-radius: 5px;
          padding: 10px 5px;
          margin: 5px;
        }

        &:not(:last-child) {
          margin-right: 5px !important;
        }

        .lc-softInfos {
          padding: 10px;
          border: none;

          .lc-time {
            color: $primary;
            font-size: 1.1em;
          }
        }
      }
    }
  }

  .lc-transport {
    margin-bottom: 15px;

    @include font-small;

    .lc-transport-title {
      font-weight: 500;
      font-size: 1.1em;

      // Override PMR style for route calculation
      .lc-route-calculation-pmr {
        display: inline-flex;
        align-items: center;
      }
    }

    .lc-noSolutionTransport {
      padding: 10px 0 0;
    }

    .lc-journey {
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0 0 15px $shadow-xs;
      color: #333333;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin: 15px 0;

      .lc-journey-head {
        border-bottom: 1px solid $border-xs;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #888;
        padding-inline-start: 0px;
        list-style-type: none;
        margin: 0px;

        .lc-time {
          display: flex;
          align-items: center;
          text-align: initial;

          span {
            display: flex;
            align-items: center;

            span {
              padding-left: 5px;
              color: $primary;
              text-align: center;
            }

            &:not(:first-child):not(:last-child) {
              padding: 0 10px;
            }
          }
        }

        .lc-leaf {
          height: 15px;
        }

        .lc-walk div,
        .lc-co2 div {
          display: flex;
          align-items: center;
          font-size: 0.9em;
          white-space: nowrap;
        }

        li {
          padding: 10px;

          &:not(:last-child) {
            border-right: 1px solid $border-xs;
          }

          &:first-child {
            flex: 1;
            text-align: center;
          }
        }
      }

      .lc-journey-warning {
        border-bottom: 1px solid $border-xs;
        padding: 10px;
        color: #888;
        display: flex;
        align-items: center;

        .lc-icon {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          z-index: 9999;
          background: url(assetsPath("/assets/images/disruptions/delays.svg")) center center no-repeat;
        }
      }

      .lc-journey-content {
        align-items: center;
        display: flex;
        position: relative;

        .lc-journey-time {
          border-right: 1px solid $border-xs;
          padding: 10px;
          min-width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 70px;

          span {
            font-size: 1.6em;
            font-weight: 500;
          }
        }

        .lc-journey-sections {
          align-items: center;
          display: flex;
          flex: 1;
          overflow: auto;
          padding: 20px 50px 20px 15px;
          white-space: nowrap;

          .lc-line {
            border: none;

            &:hover {
              background: none;
            }
          }

          .lc-delimiter {
            color: #cccccc;
            font-weight: 600;
          }

          .lc-section {
            padding: 0 2px;
            display: flex;
            align-items: center;
          }

          .lc-journey-sections-offset {
            min-height: 1px;
            padding: 0 20px;
          }
        }

        .lc-journey-sections-arrow {
          background: linear-gradient(to left, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
          border-bottom-right-radius: 10px;
          padding: 24px 10px 24px 25px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          z-index: 9999;
        }
      }

      .lc-journey-disruption-icon {
        img {
          height: 25px;
          width: 25px;
        }
      }

      .lc-journey-disruption {
        display: flex;
        align-items: center;
        &.lc-disruption-blocking {
          background-color: rgba($error, 0.2);
        }
        &.lc-disruption-delays {
          background-color: rgba($warning, 0.2);
        }
        &.lc-disruption-informations {
          background-color: rgba($info, 0.2);
        }

        border-top: 1px solid $border-xs;
        padding: 10px;
        border-radius: 0 0 5px 5px;

        .lc-journey-disruption-icon {
          min-width: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .lc-journey-disruption-avoid {
          display: flex;
          flex-direction: column;

          span {
            text-decoration: underline;
          }
        }
      }

      &:not(.lc-active) {
        background: white;
        border: none;
        box-shadow: 2px 2px 8px $shadow-xxs;

        .lc-journey-sections-arrow {
          background: linear-gradient(to left, white 50%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }

    &.lc-leave-earlier-or-later {
      display: flex;
      justify-content: space-between;

      .lc-time {
        height: 18px;
      }

      .lc-leave {
        cursor: pointer;
        display: flex;
        background-color: white;
        color: #58585a;
        align-items: center;
        padding: 5px 18px 5px 10px;
        border-radius: 4px;
      }
    }
  }

  .lc-route-calculation-legal-notice {
    margin: 0 0 15px;
    padding: 15px;
    display: flex;
    align-items: center;

    @include font-x-small;

    a {
      display: flex;

      img {
        height: 15px;
        margin: 0 5px;
      }
    }
  }
}

.lc-section,
.lc-section-part-type {
  .lc-mode {
    display: flex;
    padding-left: 8px;
    margin-right: -3px;

    img {
      width: 25px;
    }

    @include mobile {
      img {
        height: calc(#{$line-image-height} - 5px);
      }
    }
  }
}

.lc-roadmap {
  overflow-y: auto;
  padding: 20px 10px;

  @include font-small;

  .lc-roadmap-summary {
    display: flex;
    align-items: center;
    margin: -20px -10px 20px;
    border-bottom: 1px solid $border-xs;
    color: #888;
    padding-inline-start: 0px;
    list-style-type: none;

    @include font-small;

    .lc-duration {
      flex: 1;
      padding: 10px;

      span {
        color: $text;
        font-weight: 500;
      }
    }

    li.lc-walk,
    li.lc-co2 {
      display: flex;
      align-items: center;
      padding: 10px;
      border-left: 1px solid $border-xs;
      height: auto;

      div {
        display: flex;
      }

      .lc-leaf {
        height: 15px;
      }
    }

    @include mobile {
      margin: -20px -10px 20px;
    }
  }

  .lc-section-part-top,
  .lc-section-part-bottom,
  .lc-section-part-content {
    display: grid;
    cursor: pointer;
    grid-template-columns: 60px 40px 1fr;

    .lc-section-part-time {
      font-weight: bold;
      text-align: center;
    }

    .lc-section-part-border {
      position: relative;

      .lc-section-part-bull {
        background: #ffffff;
        border: 3px solid #333333;
        border-radius: 50%;
        height: 10px;
        left: 12px;
        position: absolute;
        top: 0;
        width: 10px;

        &.lc-impacted {
          background: orange;
        }

        &.lc-image-blocking {
          background: url(assetsPath("/assets/images/disruptions/marker-blocking.svg")) no-repeat center;
          border: 0px none transparent;
          width: 32px;
          height: 32px;
          margin-left: -8px;
          margin-top: -8px;
        }
        &.lc-image-delays {
          background: url(assetsPath("/assets/images/disruptions/marker-delays.svg")) no-repeat center;
          border: 0px none transparent;
          width: 32px;
          height: 32px;
          margin-left: -8px;
          margin-top: -8px;
        }
      }
    }

    .lc-section-part-type {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-bottom: 20px;

      .lc-root-icon {
        margin-left: 100px;
        background: white;
      }
    }

    .lc-section-part-details {
      padding: 10px 0 20px;

      ul {
        padding-inline-start: 0px;
        list-style-type: none;
        margin-top: 0px;
      }

      .lc-little {
        font-size: 0.9em;

        .lc-availability {
          img {
            width: 15px;
            padding-left: 5px;
          }
        }
      }

      strong {
        font-weight: 500;
      }

      .lc-details {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 0.9em;
        font-weight: 500;
        margin-bottom: 5px;
        color: $primary;

        &.with-disruptions {
          margin-top: 10px;
        }

        .lc-details-collapse {
          color: $primary;
          font-weight: 500;
          padding-right: 5px;
        }

        img {
          height: 20px;
          width: 15px;
          vertical-align: -5px;
          &.lc-rotate-180 {
            transform: rotate(180deg);
          }
        }
      }

      .lc-sections-part-details-stops {
        padding-inline-start: 0px;
        list-style-type: none;
        margin: 0px;

        .lc-section-part-details-stops {
          font-weight: 400;
          padding: 2px 0;
          position: relative;
          font-size: 0.9em;
          color: $text;
          margin-bottom: 5px;

          .lc-section-part-details-stops-bull {
            background: #ffffff;
            border: 2px solid #576369;
            border-radius: 50%;
            height: 6px;
            left: -25px;
            position: absolute;
            top: 5px;
            width: 6px;

            &.lc-impacted {
              background: orange;
            }

            &.lc-image-blocking {
              background: url(assetsPath("/assets/images/disruptions/marker-blocking.svg")) no-repeat center;
              border: 0px none transparent;
              width: 26px;
              height: 26px;
              margin-left: -8px;
              margin-top: -8px;
            }
            &.lc-image-delays {
              background: url(assetsPath("/assets/images/disruptions/marker-delays.svg")) no-repeat center;
              border: 0px none transparent;
              width: 26px;
              height: 26px;
              margin-left: -8px;
              margin-top: -8px;
            }
          }

          .lc-pmr {
            width: 13px;
            height: 13px;
          }
        }
      }

      .lc-sections-part-details-path {
        margin: 0px;
        padding-inline-start: 0px;

        .lc-section-part-details-path {
          font-size: 0.85em;
          list-style-type: none;

          .lc-section-part-details-walk-direction {
            img {
              height: 20px;
              vertical-align: -5px;
            }
          }
        }
      }
    }

    .lc-pmr {
      background: url(assetsPath("/assets/images/pmr.svg")) no-repeat center;
      height: 20px;
      width: 20px;
      margin-left: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.lc-tab-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #f1f5f5;

  .lc-tab {
    cursor: pointer;
    flex: 1;
    margin-bottom: -2px;
    padding: 10px !important;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    background: #f1f5f5;

    span {
      font-size: 0.8em;
      opacity: 0.6;
    }

    &.lc-active {
      font-weight: bold;
      background: white !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom: 1px solid $border-xs;

      &:after {
        position: absolute;
        content: "";
        height: 5px;
        width: 36px;
        background-color: $primary;
        left: 50%;
        margin-left: -18px;
        bottom: -3px;
        border-radius: 8px / 5px;
        z-index: 1;
      }
    }
  }
}

.lc-tab-panel {
  display: none;

  .lc-empty {
    padding: 20px;
    @include font-small;
  }

  &.lc-active {
    display: block;
  }
}

.lc-group {
  cursor: pointer;
  padding: 15px 20px 5px;

  &:not(:last-child) {
    border-bottom: 1px solid $border-xs;
  }

  .lc-group-offset-bottom {
    margin-bottom: 15px;
  }

  .lc-category {
    padding: 10px 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 0;

    @include font-medium;

    img {
      padding-right: 10px;
    }
  }

  // Yeah yeah, nested class ... (look Services TCL div in around for ex.)
  .lc-group {
    padding: 0;
  }

  .lc-ul-places {
    margin: 0px;

    list-style-type: none;
    padding: 0 0 0 20px;

    .lc-place {
      .lc-place-content {
        &.lc-selected {
          padding-top: 10px;
          border-top: 1px solid $primary;
        }
      }

      .lc-place-infos {
        padding: 10px 0;
        border-bottom: 1px solid $primary;
      }
    }
  }

  .lc-place {
    cursor: pointer;
    padding: 5px;

    @include font-medium;

    .lc-bss,
    .lc-parcs {
      display: flex;
      margin-top: 5px;
      align-items: center;
    }

    .lc-parcs {
      padding: 5px 0;

      .lc-parcs-title {
        font-weight: 500;
      }

      .lc-seats {
        padding: 0 10px 0 0;
      }
    }

    .lc-place-content {
      flex-direction: column;
      margin-left: 5px;

      .lc-selected {
        font-weight: 500;
      }

      .lc-parking-seats-informations {
        flex-direction: column;
      }

      .lc-realtime-seats {
        background: #e0e0e0;
        border-radius: 5px;
        padding: 3px;
        align-self: flex-start;
        margin-bottom: 5px;

        img {
          width: 15px;
          padding-left: 3px;
        }
      }

      .lc-place-infos {
        flex-direction: column;
        margin: 0;
        font-weight: 300;

        .lc-bss {
          margin-left: 0;
          justify-content: flex-start;
        }

        .lc-cars {
          margin-left: 0;
          display: flex;
          align-items: baseline;
          .lc-car {
            margin-right: 10px;
            img {
              margin-left: 5px;
              width: 30px;
            }
          }

          .lc-realtime-gif {
            width: 15px;
          }
        }

        .lc-station-schedules {
          display: flex;
          flex-direction: column;
          margin: 0;
          position: relative;

          div {
            flex: initial;
            margin-left: initial;
            justify-content: initial;
          }

          .lc-station-schedules-header {
            border-bottom: 1px solid $border-m;
            padding: 5px 0;
            font-weight: 400;
          }

          .lc-station-schedules-content {
            display: flex;
            flex-direction: column;
            padding: 5px 0;
            font-weight: 500;
            min-height: 20px;

            &.lc-not-empty {
              min-height: 100px;
            }
          }

          .lc-station-schedules-header,
          .lc-station-schedules-entry {
            display: flex;
            div:nth-child(1) {
              flex: 2;
            }

            div:nth-child(2) {
              flex: 3;
              display: flex;
              flex-direction: column;
            }

            .lc-station-schedules-entry-mode {
              font-size: 0.9em;
              color: #aaa;
              font-weight: 400;
            }

            del {
              font-size: 0.9em;
              color: #999;
            }
          }

          .lc-station-pagination {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;

            .lc-station-pagination-previous,
            .lc-station-pagination-next {
              padding: 3px 6px;
              border: 1px solid rgba(0, 0, 0, 0.15);
              border-radius: 5px;
              margin: 0 2px;

              img {
                width: 15px;
                height: 15px;
                transform: rotate(-90deg);
              }
            }

            .lc-station-pagination-previous {
              margin-right: 5px;

              img {
                transform: rotate(90deg);
              }
            }
          }
        }

        .lc-address,
        .lc-services,
        .lc-mail,
        .lc-phone,
        .lc-info {
          flex-direction: column;
          margin-top: 5px;
        }

        .lc-wheelchair {
          margin-top: 5px;
          display: flex;
        }

        .lc-address-title,
        .lc-services-title,
        .lc-mail-title,
        .lc-phone-title,
        .lc-wheelchair-title {
          font-weight: 500;
        }

        .lc-loading {
          align-items: flex-start;
          padding: 5px;

          img {
            padding-bottom: 0;
          }
        }

        .lc-opening-hours {
          margin-top: 5px;
          flex-direction: column;

          .lc-opening-hours-title {
            font-weight: 500;
          }

          .lc-opening-hours-list {
            flex-direction: column;

            span {
              display: block;
            }
          }
        }
      }
    }
  }
}

.lc-group-name {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  transition: all 250ms ease-in-out;

  .lc-mode {
    width: 100%;
  }

  .lc-group-mode {
    font-weight: 500;
    display: flex;
    align-items: center;

    .lc-group-img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    @include small-mobile {
      @include font-small;
    }
  }

  .lc-group-mode-logo {
    max-width: 45px;
    padding: 0 18px 0 10px;
  }

  .lc-group-header {
    align-items: center;
    display: flex;
    flex: 1;

    img {
      height: 40px;
      padding-right: 10px;
      width: 30px;
      object-fit: initial;
    }
  }

  .lc-arrow-group {
    display: flex;
    margin-right: -5px;

    img {
      height: 25px;
      transition: all ease-in 150ms;
      width: 25px;

      &.lc-closed {
        transform: rotate(-90deg);
      }
    }
  }
}

.lc-bikes,
.lc-electric-bikes,
.lc-seats {
  display: flex;
  align-items: center;

  img {
    width: 25px;
    padding-left: 5px;
  }
}

.lc-seats,
.lc-electric-bikes {
  padding-left: 15px;

  img {
    width: 12px;
  }
}

.lc-time-picker-popup {
  z-index: 99999;

  .lc-rc-time-picker-panel-inner {
    box-shadow: $shadow-xs 2px 2px 10px;
  }

  .lc-rc-time-picker-panel-input {
    font-size: 1.1em;
  }
}

.lc-time-picker-group {
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
  }

  img {
    height: 25px;
    padding-right: 5px;
  }

  .lc-time-picker {
    input {
      width: 110px;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 1em;

      &:focus {
        font-size: 16px;
      }
    }
  }
}

.lc-walk,
.lc-bss,
.lc-bike,
.lc-leaf {
  height: 18px;
}

.lc-is-pmr {
  background: url(assetsPath("/assets/images/pmr.svg")) no-repeat center;
  margin-left: 5px;
  height: 15px;
  width: 15px;

  &.lc-section {
    display: inline-block;
    vertical-align: -2px;
  }
}

.lc-is-no-pmr {
  background: url(assetsPath("/assets/images/no-pmr.svg")) no-repeat center;
  margin-left: 5px;
  height: 15px;
  width: 15px;
}

.lc-is-tad {
  background: url(assetsPath("/assets/images/tad.svg")) no-repeat center;
  margin-left: 5px;
  height: 15px;
  width: 15px;
}

.lc-active-line {
  .lc-line-header {
    border-bottom: 1px solid $border-m;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    padding: 10px;
    position: relative;

    .lc-line:hover {
      background: initial;
    }

    .lc-timetableOptions {
      min-width: 100px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999;
      background: white;
      box-shadow: -2px 3px 10px $shadow-xs;

      .lc-timetableOptionsItem {
        text-align: center;
        padding: 10px;
        cursor: pointer;

        @include font-small;

        &:hover {
          background: $hover;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $border-xs;
        }
      }
    }

    .lc-direction {
      flex: 1;
      margin-left: 10px;

      &.lc-tad-hide-direction {
        display: none;
      }

      @include font-small;

      &.lc-rer {
        font-size: 1.1em;
      }

      .lc-tadLink {
        display: block;
        text-decoration: none;
        font-size: 1.2em;
        color: $primary;
        font-weight: 500;
        margin-top: 10px;
      }
    }

    .lc-tools {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .lc-line-stop-informations {
    &:not(:empty) {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
  }

  .lc-line-stop-information,
  .lc-line-tad-header {
    padding: 10px 20px 0px 20px;
    @include font-small;
  }

  .lc-line-stop-information {
    display: flex;

    div:nth-child(2) {
      flex: 1;
      margin-left: 25px;
    }
  }

  .lc-line-tad-informations {
    position: relative;
    padding: 10px 8px;
    border-bottom: 1px solid $border-m;
    @include font-small;

    &.lc-tad-hide-thermo {
      border-bottom: 0px solid $border-m;
    }

    img {
      padding: 10px 20px 10px 20px;
      max-width: 15px;
    }

    .lc-line-tad-phone,
    .lc-line-tad-website {
      display: flex;
      flex: 1 1;
      align-items: center;
    }
  }

  .lc-stops {
    overflow: auto;
    padding: 10px;

    &.lc-tad-hide-thermo {
      display: none;
    }

    .lc-ul-stops {
      padding-inline-start: 0px;
      margin: 0px;
      list-style-type: none;
    }

    &.lc-tad-no-thermo {
      padding-left: 25px;
    }

    @include font-small;

    .lc-stop {
      cursor: pointer;
      display: flex;

      // Borne TCL
      &.lc-selected {
        background-color: #{$primary}15;
        border-radius: 5px;
        //font-size: 1.2em;

        .lc-seeTimetable {
          //font-size: 0.7em !important;
        }
      }

      .lc-border {
        border-left: 4px solid;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-left: 20px;
        margin-top: 9px;

        @include mobile {
          margin-left: 18px !important;
        }
      }

      &:not(:first-child) .lc-border {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
      }

      .lc-first-border {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-top: 9px !important;
      }

      &:last-child .lc-border {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 17px;
      }

      .lc-point {
        position: relative;
        background: #ffffff;
        border-radius: 50%;
        height: 6px;
        margin: 8px 28px 0 -7px;
        width: 6px;
        box-sizing: content-box;

        .lc-pulsate {
          z-index: 0;
          width: 10px;
          height: 10px;
        }

        .lc-crossed:before,
        .lc-crossed:after {
          position: absolute;
          left: 2px;
          content: " ";
          height: 15px;
          top: -4px;
          width: 2px;
          background-color: #333;
        }

        .lc-crossed:before {
          transform: rotate(45deg);
        }

        .lc-crossed:after {
          transform: rotate(-45deg);
        }

        .lc-image-blocking {
          background: url(assetsPath("/assets/images/disruptions/marker-blocking.svg")) no-repeat center;
          width: 26px;
          height: 26px;
          margin-left: -10px;
          margin-top: -10px;
        }
        .lc-image-delays {
          background: url(assetsPath("/assets/images/disruptions/marker-delays.svg")) no-repeat center;
          width: 26px;
          height: 26px;
          margin-left: -10px;
          margin-top: -10px;
        }
      }

      .lc-stop-terminus {
        height: 12px;
        margin: 5px 25px 0 -10px;
        width: 12px;

        .lc-crossed:before,
        .lc-crossed:after {
          left: 5px;
          top: -2px;
        }

        .lc-image-blocking {
          background: url(assetsPath("/assets/images/disruptions/marker-blocking.svg")) no-repeat center;
          width: 32px;
          height: 32px;
          margin-left: -10px;
          margin-top: -10px;
        }
        .lc-image-delays {
          background: url(assetsPath("/assets/images/disruptions/marker-delays.svg")) no-repeat center;
          width: 32px;
          height: 32px;
          margin-left: -10px;
          margin-top: -10px;
        }
      }

      .lc-stop-name {
        flex: 1;
        padding: 5px 0 8px;

        &.lc-tad-no-thermo {
          margin-left: -15px;

          &.lc-selected {
            margin-left: -20px;
          }
        }

        .lc-stop-and-tools {
          display: flex;
          align-items: center;

          span {
            color: white;
            font-weight: bold;
            padding: 5px;
            border-radius: 5px;
            margin-left: -5px;
            margin-top: -2px;
          }
        }

        .lc-connections {
          display: flex;

          .lc-connection {
            border-radius: 2px;
            font-size: 0.7em;
            font-weight: 500;
            margin: 0 3px 10px 0;
            padding: 2px 4px;
          }
        }

        &.lc-selected {
          margin-left: -10px;

          .lc-connections {
            padding-left: 10px;
          }

          .lc-selectedStop {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding-left: 10px;
          }

          .lc-selectedContent {
            display: flex;
            cursor: default;
            justify-content: space-between;
            align-items: center;

            .lc-loader-gif {
              padding: 10px 0;
              margin-left: 5px;
            }

            .lc-schedules {
              padding: 5px 10px 0;

              .lc-nextSchedules {
                font-size: 0.9em;
                padding: 0 0 5px;
              }

              .lc-schedule {
                align-items: center;
                display: flex;
                padding: 5px 0;
                font-size: 0.9em;
                font-weight: bold;

                img {
                  height: 12px;
                  margin: 0 5px;
                }
              }

              .lc-no-schedules {
                padding: 0;
                margin-top: 5px;
                font-style: italic;
              }
            }

            &.lc-no-schedules {
              justify-content: flex-end;
            }

            .lc-seeTimetable {
              align-items: center;
              border-radius: 5px;
              cursor: pointer;
              display: flex;
              padding: 10px;
              margin-right: 10px;

              border: 2px solid $border-xs;
              border-radius: 5px;

              @include font-small;

              & img {
                height: 25px;
                padding-right: 10px;
              }

              &:hover {
                background: $hover;
              }
            }
          }
        }
      }
    }
  }

  .lc-timetable-stop {
    border-bottom: 1px solid $border-m;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include font-small;

    .lc-print {
      cursor: pointer;
      background: url(assetsPath("/assets/images/print.svg")) no-repeat center;
      width: 20px;
      height: 20px;
      margin-right: -5px;
    }

    @include mobile {
      padding: 10px 15px !important;

      .lc-print {
        margin-right: 0 !important;
      }
    }
  }

  .lc-odt {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .lc-no-schedules {
    padding: 0 10px 10px;
    @include font-small;
  }

  .lc-timetable {
    padding: 0 20px;
    margin-top: 30px;
    @include font-small;

    .slick-dots {
      position: absolute;
      top: -34px;
      height: 15px;
      width: auto;
      left: 44%;

      @include small-mobile {
        left: 42%;
      }

      li {
        margin: 0;
        width: 15px;
        height: 15px;

        button {
          &:before {
            font-family: inherit;
            font-size: 20px;
          }
        }
      }
    }

    .slick-arrow {
      position: absolute;
      top: -20px;
      display: flex !important;
      align-items: center;

      &:before {
        font-family: inherit;
        color: black;
        font-size: 1.2em;
      }

      &.slick-prev,
      &.slick-next {
        font-size: 1em;
        color: inherit;
        width: 85px;
      }

      &.slick-prev {
        left: 20px;
        span {
          margin-left: 3px;
        }
      }
      &.slick-next {
        right: 20px;
        flex-direction: row-reverse;
        span {
          margin-right: 3px;
        }
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;

      tr {
        th {
          position: sticky;
          color: $primary;
          background: white;

          &:nth-child(even) {
            background: #f4f4f4;
          }
        }

        td {
          text-align: center;
          padding: 2px 0;

          span {
            @include font-small;
            color: red;
            font-weight: 300;
          }

          &:nth-child(even) {
            background: #f4f4f4;
          }
        }
      }
    }
  }

  .lc-notes-timetable {
    display: flex;
    align-items: flex-start;
    @include font-small;
    border-top: 1px solid $border-xs;
    padding: 10px 0;

    img {
      width: 20px;
      margin-right: 10px;
    }
  }

  .lc-tad {
    background: url(assetsPath("/assets/images/tad.svg")) no-repeat center;
    width: 60px;
    height: 20px;
    background-size: cover;
    border-radius: 2px;
    border: 1px solid $border-m;
  }

  .lc-otherDirections {
    padding: 10px 0;
    @include font-small;

    .lc-otherdirectionsContent {
      .lc-otherdirectionsContentLetter {
        color: red;
        font-weight: 300;
        font-size: 0.9em;
      }
    }
  }

  @include small-mobile {
    .lc-timetable-stop {
      padding: 15px 20px;
    }
  }
}

.lc-lines {
  display: grid;
  text-align: center;
  grid-template-columns: repeat($grid-size, 1fr);
  grid-gap: 10px;

  &.lc-large {
    grid-template-columns: repeat($grid-size, 1fr);
  }

  &.lc-small {
    grid-template-columns: repeat($grid-size-mobile, 1fr);

    &.lc-line-with-direction {
      text-align: left;

      .lc-line {
        min-width: 40px !important;
        width: 40px !important;
      }
    }
  }

  &.lc-line-with-direction {
    grid-template-columns: repeat(1, 1fr);
    .lc-attribute-line {
      display: flex;
      align-items: center;

      .lc-line {
        padding: 5px 5px;
        min-width: 50px;
        width: 50px;
        text-align: center;

        &.lc-code {
          @include font-small;
        }

        &.lc-mode {
          @include font-xx-small;
        }
      }

      .lc-name {
        padding-left: 10px;
        text-align: left;
      }
    }
  }

  .lc-without-routes {
    position: relative;

    img,
    .lc-line {
      filter: grayscale(1);
    }

    .lc-striked-line {
      position: absolute;
      content: "";
      left: 2px;
      top: 23px;
      right: 2px;
      border-top: 4px solid red;
      border-radius: 2px;
      transform: rotate(-36deg);

      @include mobile {
        top: 18px;
        left: 7px;
        right: 7px;
      }
    }
  }
}

.lc-line {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 7px;
  border: if($line-with-border, 2px solid $border-xs, none);
  border-radius: 5px;

  &[role="button"]:hover {
    background: $hover;
  }

  .lc-roadmap & {
    &:hover {
      background: none;
    }
  }

  &.lc-with-direction {
    padding: 5px 5px;
    border: 2px solid rgba(0, 0, 0, 0.08);
    width: 50px;
    font-size: 0.53em;
    text-align: center;

    &.lc-code {
      @include font-small;
    }

    &.lc-mode {
      @include font-xx-small;
    }
  }

  .lc-pertIcon,
  .lc-blockIcon {
    width: 14px;
    height: 14px;
    margin-left: -2px;
    z-index: 9999;
    background: url(assetsPath("/assets/images/disruptions/delays.svg")) center center no-repeat;
  }

  .lc-blockIcon {
    background: url(assetsPath("/assets/images/disruptions/blocking.svg")) center center no-repeat;
  }

  img {
    height: $line-image-height;
    max-width: 65px;
  }

  @include mobile {
    padding: 7px !important;

    img {
      height: calc(#{$line-image-height} - 5px);
      max-width: 50px;
    }
  }
}

.lc-line-label {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 60px;
}

.lc-line-code {
  border-radius: 2px;
  cursor: pointer;
  font-weight: 500;
  min-width: 35px;
  display: flex;
  justify-content: center;
  padding: 3px 5px;
  white-space: nowrap;
}

.lc-autocomplete-line {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;

  .lc-line {
    min-width: 40px;

    &.lc-line-with-direction {
      padding: 5px 5px;
      width: 50px;
      text-align: center;

      &.lc-code {
        @include font-small;
      }

      &.lc-mode {
        @include font-xx-small;
      }
    }

    img {
      height: calc(#{$line-image-height} - 5px);
      max-width: 40px;
    }
  }

  .lc-autocompleteDirection {
    font-weight: 600;
  }
}

.lc-long-line-code {
  letter-spacing: -1.5px;
}

.lc-capitalize::first-letter {
  text-transform: capitalize;
}

.lc-error,
.lc-warning,
.lc-blocking,
.lc-delays,
.lc-informations {
  color: #ffffff;
  margin: 10px 15px;
  padding: 0 10px;
  border-left: 3px solid;

  .lc-disruptionSeverity {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-top: 5px;

    .lc-icon {
      width: 15px;
      padding: 0;
      margin: 0 5px 0 0;
    }
  }

  .lc-disruptionSeverity::first-letter {
    text-transform: capitalize;
  }

  .lc-disruptionTitle {
    font-weight: bold;
    margin-top: 5px;
  }

  .lc-disruptionContent {
    color: #7a7a7a !important;
    margin: 5px 0;
    font-size: 14px;
    cursor: pointer;

    &.lc-empty {
      font-style: italic;
    }

    p {
      margin: 0 0 5px !important;
    }
  }

  .lc-disruption-dates {
    font-weight: bold;

    .lc-disruption-dates-more {
      color: #7a7a7a;
      padding-top: 5px;
      font-weight: normal;
      cursor: pointer;
    }

    .lc-collapsed {
      padding-top: 5px;
    }
  }
}

.lc-error,
.lc-warning {
  color: $error;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-left: none;
  padding: 10px;

  img {
    background-size: contain !important;
    width: 15px;
    height: 15px;
    margin: 0 10px;
  }
}

.lc-blocking,
.lc-delays,
.lc-informations {
  margin: 0 0 15px;
  color: $text;

  .lc-icon {
    background-size: contain !important;
    width: 15px;
    height: 15px;
    margin: 0 15px 0 5px;
  }

  .lc-disruption {
    flex: 1;
  }
}

.lc-warning {
  color: $warning;
}

.lc-blocking {
  border-left-color: $error;

  .lc-disruptionSeverity {
    color: $error;

    .lc-icon {
      background: url(assetsPath("/assets/images/disruptions/blocking.svg")) center center no-repeat;
    }

    &.lc-white {
      .lc-icon {
        background: url(assetsPath("/assets/images/disruptions/blocking-white.svg")) center center no-repeat;
      }
    }
  }
}

.lc-delays {
  border-left-color: $warning;

  .lc-disruptionSeverity {
    color: $warning;

    .lc-icon {
      background: url(assetsPath("/assets/images/disruptions/delays.svg")) center center no-repeat;
    }

    &.lc-white {
      .lc-icon {
        background: url(assetsPath("/assets/images/disruptions/delays-white.svg")) center center no-repeat;
      }
    }
  }
}

.lc-informations {
  border-left-color: $info;

  .lc-disruptionSeverity {
    color: $info;

    .lc-icon {
      background: url(assetsPath("/assets/images/disruptions/informations.svg")) center center no-repeat;
    }
  }
}

.lc-tool-swap,
.lc-tool-timetable,
.lc-tool-route-calculation,
.lc-tool-streetview-link {
  cursor: pointer;
  height: 18px;
  margin: 3px;
  width: 25px;

  &.lc-toolSmall {
    height: 15px;
    margin: 0;
  }
}

.lc-tool-swap {
  background: url(assetsPath("/assets/images/swap.svg")) no-repeat center;
}

.lc-tool-timetable {
  background: url(assetsPath("/assets/images/print.svg")) no-repeat center;
  display: block;
  height: 23px;
}

.lc-tool-route-calculation,
.lc-tool-streetview-link {
  background: url(assetsPath("/assets/images/menu/route-calculation.svg")) no-repeat center;
  background-color: none;
  padding: 5px;
  border: 1px solid $border-m;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &.lc-tool-streetview-link {
    background: url(assetsPath("/assets/images/streetview.svg")) no-repeat center;
  }

  &:hover {
    background-color: $hover;
  }
}

.lc-root-icon {
  border-radius: 20px;
  color: if(luminance($primary) > 0.5, #333333, #ffffff);
  display: flex;
  align-items: center;
  height: 18px;
  padding: 8px 5px;

  .lc-car {
    height: 16px !important;
  }

  .lc-icon {
    align-self: center;
    background: transparent;
    height: 100%;
  }
}

.lc-print-itinerary-map {
  height: 900px;
  width: 900px;

  border: solid #888 1px;

  margin-top: 20px;

  &.small {
    margin-top: 0px;
    height: 400px;
    width: 400px;
  }
}
