$slider-color: #333;

.lc-map {
  position: relative;
  overflow: hidden;

  @include desktop {
    display: flex;
    flex: 1;
  }

  @include mobile {
    width: 100%;
  }
}

.lc-mapContainer {
  flex: 1;
  background: #f4f4f4 !important;

  font-family: if($font != "", $font !important, initial);

  @if $header-direction != row {
    &.lc-with-header {
      height: calc(calc(var(--vh, 1vh) * 100) - #{$header-height});
    }
  }

  @include mobile {
    height: calc(var(--vh, 1vh) * 60);
    &.lc-no-board {
      height: calc(var(--vh, 1vh) * 100);
    }
    &.lc-expanded-map {
      height: calc(var(--vh, 1vh) * 90);
    }

    &.lc-with-header {
      height: calc(calc(var(--vh, 1vh) * 60) - #{$header-height-mobile});
      &.lc-no-board {
        // 233
        height: calc(calc(var(--vh, 1vh) * 100) - #{$header-height-mobile});
      }
    }
  }

  .leaflet-tilelayer-above-pane {
    z-index: 401;
  }
}

.lc-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  @include mobile {
    top: 0;
    left: calc(100vw / 2);
  }
  @include desktop {
    @if $header-direction == row {
      top: 0;
      left: calc((100vw - #{$header-height}) / 2);
    } @else {
      top: $header-height;
      left: calc(100vw / 2);
    }
  }
  bottom: 0;
  width: 2px;
  background: $slider-color;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 400;

  .lc-between {
    position: absolute;
    background-image: url(assetsPath("/assets/images/swipe.svg"));
    background-repeat: no-repeat;
    background-size: 65%;
    background-position: center;
    background-color: #888;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    z-index: 10;
  }

  .lc-before,
  .lc-after {
    color: white;
    width: 90px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    min-width: 100px;
    position: absolute;
  }

  .lc-before {
    background: $slider-color;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #fff;
    padding: 10px;
    padding-right: 15px;
    right: 0;
  }

  .lc-after {
    background: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: $slider-color;
    padding: 10px;
    padding-left: 25px;
    left: 0;
  }
}

.leaflet-layer {
  &.lc-overlay {
    z-index: auto !important;
  }
}

.lc-leaflet-map-macaron {
  position: absolute;
  padding: 30px;
  top: 0px;
  right: 0px;
  width: 100px;
  z-index: 1000;
}

.lc-leaflet-control-add-entry,
.lc-leaflet-control-tutorial,
.lc-leaflet-control-legend,
.lc-leaflet-control-streetview {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border-radius: 4px;
  cursor: pointer !important;

  img {
    padding: 3px;
  }

  &:hover {
    background-color: #f4f4f4;
  }
}

.lc-legend-panel {
  position: fixed;
  bottom: 50px;
  right: 55px;
  padding: 10px;
  z-index: 9998;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 200px;
  height: 200px;

  @include mobile {
    width: auto !important;
    top: 10px;
    left: 10px;
    right: 10px;
  }

  .lc-legend-close {
    position: absolute;
    padding: 10px 10px 0px 10px;
    text-align: right;
    right: 0px;
    top: -5px;
  }

  .lc-legend-img {
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

.lc-stop-marker {
  @if variable-exists(stop-marker-width) and variable-exists(stop-marker-height) {
    @include markerSize($stop-marker-width, $stop-marker-height);
  } @else {
    @include markerSize(26, 26);
  }
}

.lc-infobox {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  margin: 0;

  border-radius: 5px;
  background: #ffffff;
  box-shadow: 2px 2px 10px $shadow-xs;

  font-family: $font, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif;
  font-size: 1.166666em;

  &:before {
    content: "";
    z-index: 99999;
    width: 0px;
    position: fixed;
    left: -14px;
    height: 0px;
    top: 12px;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent var(--lc-infobox-color, $primary) transparent transparent;
  }

  &.lc-no-arrow {
    &:before {
      content: none;
    }
  }

  @include small-mobile {
    font-size: 1em;

    .lc-infobox-content {
      .lc-line {
        min-width: 35px !important;
        padding: 6px 5px !important;
      }
    }
  }

  .lc-infobox-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;

    padding: 10px;

    color: var(--lc-infobox-text-color, $secondary);
    border-bottom: 1px solid $shadow-xs;
    background-color: var(--lc-infobox-color, $primary);

    font-weight: 600;

    span {
      display: flex;
      flex-direction: column;
      align-items: center;

      em {
        @include font-small;
      }
    }

    .lc-infobox-title-tools {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.lc-with-pmr {
        flex: 1;
      }

      .lc-is-pmr {
        background: url(assetsPath("/assets/images/pmr-infobox.svg")) no-repeat center;
        margin-left: 10px;
        height: 20px;
        width: 20px;
      }

      .lc-is-no-pmr {
        background: url(assetsPath("/assets/images/no-pmr.svg")) no-repeat center;
        margin-left: 5px;
        height: 15px;
        width: 15px;
      }

      .lc-close {
        background: url(assetsPath("/assets/images/clear-infobox.svg")) no-repeat center;
        margin-left: 5px;
        height: 15px;
        width: 15px;
      }

      .lc-is-tad {
        background: url(assetsPath("/assets/images/tad.svg")) no-repeat center;
        margin-left: 10px;
        height: 20px;
        width: 20px;
      }

      .lc-tool-route-calculation,
      .lc-tool-streetview-link {
        min-width: 20px;
        max-width: 20px;
        margin: -6px 0 -6px 10px;
        margin-left: 10px;

        background-size: 75%;
        background-color: $secondary;
      }
    }
  }

  .lc-infobox-services-station {
    display: flex;
    flex-direction: column;

    span {
      padding: 5px 0px 0px 10px;
    }

    .lc-services-list {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 5px 0 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    img {
      height: 25px;
      padding: 0 5px 10px 5px;
    }
  }

  .lc-infobox-content {
    min-width: 200px;
    padding: 10px;

    .lc-connections-at-stop {
      @include font-small;
      font-weight: 600;
    }

    .lc-infobox-lines {
      display: grid;

      padding: 0;

      grid-gap: 5px;
      grid-template-columns: repeat($grid-size, 1fr);

      &.lc-active-streetview {
        grid-template-columns: repeat(10, 1fr);
      }

      &.lc-line-with-direction {
        grid-template-columns: repeat(1, 1fr);

        .lc-attribute-line {
          display: flex;
          align-items: center;
          cursor: pointer;

          .lc-line {
            padding: 5px 5px;
            min-width: 50px;
            max-width: 50px;
            white-space: nowrap;

            &.lc-code {
              @include font-small;
            }

            &.lc-mode {
              @include font-xx-small;
            }
          }

          .lc-name {
            margin-left: 10px;
            text-align: left;
            font-size: 0.875em;
          }
        }
      }

      &.lc-line-with-route-direction {
        grid-template-columns: repeat(2, 1fr);
        margin: 5px 0 0 -5px;
        grid-auto-flow: column;

        .lc-line {
          line-height: 1;
          white-space: nowrap;
          padding: 5px;
          justify-content: inherit;
        }

        .lc-name {
          margin-left: 5px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &:not(:last-child) {
        padding-bottom: 5px;
      }

      .lc-line {
        display: flex;
        position: relative;
        justify-content: center;

        //min-width: 40px;
        padding: 10px 7px;

        cursor: pointer;
        text-align: center;

        .lc-tools-at-line {
          display: flex;
          flex-direction: column;
          position: absolute;
          right: 2px;
        }

        .lc-tool-line {
          &:not(:first-child) {
            padding-top: 3px;
          }
        }

        .lc-is-tad-at-line {
          background: url(assetsPath("/assets/images/tad.svg")) no-repeat center;
          height: 15px;
          width: 15px;
        }

        &[role="button"]:hover {
          cursor: pointer;

          background: $hover;
        }

        img {
          height: calc(#{$line-image-height} - 5px);
        }
      }

      @include mobile {
        grid-template-columns: repeat($grid-size-mobile, 1fr);
      }

      @include small-mobile {
        grid-template-columns: repeat($grid-size-small-mobile, 1fr);
      }
    }

    .lc-place {
      .lc-address-title,
      .lc-place-part-title {
        font-weight: bold;
      }

      .lc-place-infos {
        .lc-place-part {
          &:first-child {
            margin-top: 0px;
          }
          margin: 5px 0px;

          .lc-place-part-title,
          .lc-opening-hours-title {
            font-weight: bold;
          }
        }
      }

      .lc-place-part-phone {
        margin-top: 5px;
      }

      .lc-info {
        margin-top: 5px;
      }

      .lc-realtime-seats {
        background: #e0e0e0;
        border-radius: 5px;
        padding: 3px;
        align-self: flex-start;
        margin-bottom: 5px;

        img {
          width: 15px;
          padding-left: 3px;
        }
      }

      .lc-bike-available-title,
      .lc-bike-address-title {
        font-weight: bold;
      }

      .lc-parcs {
        padding: 5px 0;

        .lc-parcs-title {
          font-weight: bold;
        }
      }

      .lc-cars {
        padding: 5px 0;
        display: flex;
        align-items: baseline;

        .lc-car {
          margin-right: 10px;
          img {
            margin-left: 5px;
            width: 30px;
          }
        }

        .lc-realtime-gif {
          width: 15px;
        }
      }
    }
  }
}

.lc-streetview {
  display: block;
  max-width: 100%;
  margin: 0;
  padding: 0 !important;
  border: 0 none;
  box-sizing: border-box;

  &.stop-infobox-streetview {
    display: none;
  }
}

.lc-popup-leaflet {
  .leaflet-popup-content-wrapper {
    background: transparent;
    box-shadow: none;

    .leaflet-popup-content {
      margin: 0;
      width: auto !important;

      @include mobile {
        width: max-content !important;
        max-width: 90vw;

        .lc-infobox-title {
          white-space: initial !important;
        }
      }
    }
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  &.lc-popup-streetview {
    cursor: pointer;
  }
}

.leaflet-tooltip {
  width: auto !important;
}

.lc-tooltip-leaflet-terminus {
  border-radius: 6px;

  font-weight: 500;

  &:before {
    border: none !important;
  }

  .lc-tooltip-leaflet-terminus-with-image {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .lc-uiline {
      width: 35px !important;
      height: 35px !important;
    }

    .lc-tooltip-leaflet-terminus-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 5px;
    }

    .lc-terminus-commune {
      font-size: 0.9em;
      font-style: italic;
      margin-bottom: -8px;
    }

    .lc-terminus-name {
      font-size: 1.5em;

      &.has-town {
        transform: translateY(3px);
      }
    }
  }
}

// Circle icon marker
.lc-circle-icon-marker {
  &.car {
    @if variable-exists(circle-icon-car-width) and variable-exists(circle-icon-car-height) {
      @include markerSize($circle-icon-car-width, $circle-icon-car-height);
    } @else {
      @include markerSize(26, 26);
    }
  }

  &.bike {
    @if variable-exists(circle-icon-bike-background) {
      background: $circle-icon-bike-background;
    }
    @if variable-exists(circle-icon-bike-radius) {
      border-radius: $circle-icon-bike-radius;
    }
    @if variable-exists(circle-icon-bike-width) and variable-exists(circle-icon-bike-height) {
      @include markerSize($circle-icon-bike-width, $circle-icon-bike-height);
    } @else {
      @include markerSize(26, 26);
    }
  }

  span {
    position: absolute;
    top: -2px;
    left: -2px;

    display: block;

    width: 100%;
    height: 100%;

    border-radius: 50%;
    background-color: white;
  }
}

// Remove Leaflet Popup fade
/*.leaflet-fade-anim .leaflet-popup {
  transition: none !important;
}*/

.lc-route-calculation-markers {
  &.start-pin {
    @if variable-exists(start-pin-width) and variable-exists(start-pin-height) {
      @include markerSize($start-pin-width, $start-pin-height);
    } @else {
      @include markerSize(35, 35);
    }
  }

  &.end-pin {
    @if variable-exists(end-pin-width) and variable-exists(end-pin-height) {
      @include markerSize($end-pin-width, $end-pin-height);
    } @else {
      @include markerSize(40, 40);
    }
  }
}

.lc-polyline-decorator {
  z-index: 9999 !important;

  padding: 2px 0;

  border: 1px solid $border-m;
  border-radius: 2px;
  background: white;

  &.lc-decorator-ter {
    @if variable-exists(circle-ter-decorator) {
      border-radius: #{$circle-ter-decorator} / 50%;
    }

    @if variable-exists(border-ter-decorator) {
      border: #{$border-ter-decorator};
    }

    @if variable-exists(background-ter-decorator) {
      background: #{$background-ter-decorator};
    }

    @if variable-exists(ter-decorator-width) and variable-exists(ter-decorator-height) {
      @include polylineDecoratorSize($ter-decorator-width, $ter-decorator-height);
    } @else {
      @include polylineDecoratorSize(40, 15);
    }
  }

  &.lc-decorator-bus {
    @if variable-exists(circle-bus-decorator) {
      border-radius: #{$circle-bus-decorator} / 50%;
    }

    @if variable-exists(border-bus-decorator) {
      border: #{$border-bus-decorator};
    }

    @if variable-exists(background-bus-decorator) {
      background: #{$background-bus-decorator};
    }

    @if variable-exists(bus-decorator-width) and variable-exists(bus-decorator-height) {
      @include polylineDecoratorSize($bus-decorator-width, $bus-decorator-height);
    } @else {
      @include polylineDecoratorSize(40, 15);
    }
  }

  &.lc-decorator-bike-or-car {
    @if variable-exists(circle-bike-or-car-decorator) {
      border-radius: #{$circle-bike-or-car-decorator} / 50%;
    }

    @if variable-exists(border-bike-or-car-decorator) {
      border: #{$border-bike-or-car-decorator};
    }

    @if variable-exists(background-bike-or-car-decorator) {
      background: #{$background-bike-or-car-decorator};
    }

    @if variable-exists(bike-car-decorator-width) and variable-exists(bike-car-decorator-height) {
      @include polylineDecoratorSize($bike-car-decorator-width, $bike-car-decorator-height);
    } @else {
      @include polylineDecoratorSize(35, 15);
    }
  }

  &.lc-decorator-other {
    @if variable-exists(circle-other-decorator) {
      border-radius: #{$circle-other-decorator} / 50%;
    }

    @if variable-exists(border-other-decorator) {
      border: #{$border-other-decorator};
    }

    @if variable-exists(background-other-decorator) {
      background: #{$background-other-decorator};
    }

    @if variable-exists(padding-other-decorator) {
      padding: #{$padding-other-decorator};
    }

    @if variable-exists(other-decorator-width) and variable-exists(other-decorator-height) {
      @include polylineDecoratorSize($other-decorator-width, $other-decorator-height);
    } @else {
      @include polylineDecoratorSize(20, 15);
    }
  }

  &.lc-hide-walk {
    display: none;
  }

  &.lc-show-walk {
    display: block;
  }
}

.lc-polyline-decorator-color {
  width: auto !important;
  height: auto !important;
  margin-top: -16px !important;
  margin-left: -24px !important;
  z-index: 9999 !important;
  padding: 2px;

  background: white;
  border: 1px solid $border-m;
  border-radius: 2px;

  @if variable-exists(border-bus-decorator) {
    border: #{$border-bus-decorator};
  }

  @if variable-exists(background-bus-decorator) {
    background: #{$background-bus-decorator};
  }

  .lc-decorator {
    padding: 2px 12px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.lc-cluster {
  display: flex !important;
  align-items: center;
  justify-content: center;

  width: 23px !important;
  height: 23px !important;

  color: $primary;
  border: solid 1.2px rgba($primary, 0.8);
  border-radius: 5px;
  background-color: white;
}

/*
  Image for blocking stop
*/
.lc-image-blocking {
  background: url(assetsPath("/assets/images/disruptions/marker-blocking.svg")) no-repeat center;
  width: 26px;
  height: 26px;
  margin-left: -10px;
  margin-top: -10px;

  &.lc-stop-terminus {
    width: 32px;
    height: 32px;
    margin-left: -12px;
    margin-top: -12px;
  }
}
/*
  Image for delay stop
*/
.lc-image-delays {
  background: url(assetsPath("/assets/images/disruptions/marker-delays.svg")) no-repeat center;
  width: 26px;
  height: 26px;
  margin-left: -10px;
  margin-top: -10px;

  &.lc-stop-terminus {
    width: 32px;
    height: 32px;
    margin-left: -12px;
    margin-top: -12px;
  }
}

.lc-place-icon {
  &.lc-default {
    @if variable-exists(place-icon-default-width) and variable-exists(place-icon-default-height) {
      @include markerSize($place-icon-default-width, $place-icon-default-height);
    } @else {
      @include markerSize(26, 26);
    }
  }

  &.lc-bicycle-rental {
    @if variable-exists(place-icon-bicycle-rental-width) and variable-exists(place-icon-bicycle-rental-height) {
      @include markerSize($place-icon-bicycle-rental-width, $place-icon-bicycle-rental-height);
    } @else {
      @include markerSize(15, 15);
    }
  }

  &.lc-stations {
    @if variable-exists(place-icon-stations-width) and variable-exists(place-icon-stations-height) {
      @include markerSize($place-icon-stations-width, $place-icon-stations-height);
    } @else {
      @include markerSize(40, 28);
    }
  }

  &.lc-vcub {
    @if variable-exists(place-icon-vcub-width) and variable-exists(place-icon-vcub-height) {
      @include markerSize($place-icon-vcub-width, $place-icon-vcub-height);
    } @else {
      @include markerSize(15, 15);
    }
  }
}

#lc-legal-notice {
  cursor: pointer;
}

.lc-btn-segmentation-dl {
  position: absolute;
  z-index: 700;
  top: 20px;
  left: 42%;
  display: flex;
  font-size: 1.2em;

  div {
    width: 200px;
    cursor: pointer;
    padding: 10px 40px;
    background-color: #b9b9b9;
    color: #fff;
    text-align: center;
  }

  div.active {
    background-color: #6c2478;
  }

  .lc-segmentation {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    // border-right: none;
  }
  .lc-ddl {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.lc-intersections-popup {
  .leaflet-popup-content-wrapper {
    border-radius: 3px;
  }

  .leaflet-popup-content {
    margin: 0;
    padding: 6px;
    width: auto !important;
  }
}

.lc-intersection-content {
  display: flex;
  flex: 1;

  div {
    margin-left: 3px;
    margin-right: 3px;
  }
}
