.lc-admin-valkyrie iframe {
  display: block;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0 none;
  box-sizing: border-box;
}

.lc-admin-header {
  font-size: 1.7em;
  background-color: $primary;
  padding: 20px;

  span {
    color: $secondary;
  }
}

.lc-admin-content {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    flex-direction: column;
  }
}

.lc-admin-message {
  text-align: center;
  width: max-content;
  padding: 20px;
  margin: 20px auto;
  color: white;
  border-radius: 5px;

  &.message-success {
    background-color: green;
  }

  &.message-error {
    background-color: red;
  }

  &.message-loading {
    background-color: orange;
  }
}
