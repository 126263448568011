@mixin override {
  .lc-map .lc-slider {
    .lc-before,
    .lc-after {
      background: $primary;
      color: #333;
    }
    .lc-between {
      background-color: #95932b;
    }
  }

  .lc-uilinedetails-infos {
    @include font-medium;
  }

  .lc-pdf-button {
    color: #333 !important;
  }
}
