.lc-disruptions {
  z-index: 999;
  padding-left: 18px;
  margin-left: -18px;
  position: relative;

  &:before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid white;
    background: $error;
    position: absolute;
    top: 0px;
    left: -10px;
  }

  .lc-disruptions-head {
    position: relative;
    padding: 10px 8px;
    cursor: pointer;
    border-left: 3px solid $error;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;

    @include font-small;

    &:before {
      content: url(assetsPath("/assets/images/disruptions/blocking.svg"));
      width: 15px;
      height: 15px;
      border: 1px solid $error;
      border-radius: 50%;
      margin-right: 8px;
    }

    .lc-arrow {
      position: absolute;
      right: 3px;
      top: 12px;

      img {
        width: 20px;
        transition: all ease-in 250ms;
      }

      .lc-closed {
        transform: rotate(-90deg);
      }
    }
  }

  &.with-disruptions-opened {
    margin-left: -22px;
    border-left: 4px solid $error;
  }

  &.with-only-delays {
    border-left-color: $warning;

    &:before {
      background: $warning;
    }

    .lc-disruptions-head {
      border-left-color: $warning;
    }
  }

  &.with-only-infos {
    border-left-color: $info;

    &:before {
      background: $info;
    }

    .lc-disruptions-head {
      border-left-color: $info;
      text-transform: unset;
    }
  }
}

.lc-infobox {
  .lc-severity {
    white-space: nowrap;
    margin: 0;
    padding: 10px 10px 10px 5px;
  }
}
