$board-mobile-default-top: 220px;
$board-mobile-margin-top: 80px;
$board-mobile-translateY-offset: 160px;
$border-m: rgba(0, 0, 0, 0.15);
$border-white-xs: rgba(255, 255, 255, 0.2);
$border-xs: rgba(0, 0, 0, 0.08);
$break-mobile: 600px;
$break-small-small: 360px;
$error: #888;
$font: "Roboto";
$grid-size: 4;
$grid-size-mobile: 4;
$grid-size-small-mobile: 3;
$header-direction: row;
$header-height: 0px;
$header-height-mobile: 0px;
$hover: #f9f9f9;
$info: #888;
$library-url: "";
$line-image-height: 25px;
$line-with-border: false;
$primary: #bfbd37;
$secondary: #888;
$secondary-journey: #888;
$shadow-m: rgba(0, 0, 0, 0.3);
$shadow-xl: rgba(0, 0, 0, 0.4);
$shadow-xs: rgba(0, 0, 0, 0.15);
$shadow-xxs: rgba(0, 0, 0, 0.02);
$text: #58585a;
$tippy-color: #b2b835;
$warning: #888;
$override-styles: true;
