$font: "Roboto" !default;

// Colors
$primary: #333 !default;
// color to match with primary
$secondary: white !default;
$hover: #f9f9f9 !default;
$secondary-journey: #888 !default;
$text: #58585a !default;
$error: #ff0000 !default;
$warning: #ef662f !default;
$info: #43b77a !default;
$tippy-color: $primary !default;

// Borders
$border-white-xs: rgba(255, 255, 255, 0.2) !default;
$border-xs: rgba(0, 0, 0, 0.08) !default;
$border-m: rgba(0, 0, 0, 0.15) !default;

// Shadows
$shadow-xxs: rgba(0, 0, 0, 0.02) !default;
$shadow-xs: rgba(0, 0, 0, 0.15) !default;
$shadow-m: rgba(0, 0, 0, 0.3) !default;
$shadow-xl: rgba(0, 0, 0, 0.4) !default;

// Breakpoints CSS
$break-mobile: 600px !default;
$break-small-small: 360px !default;

// Grid
$grid-size: 4 !default;
$grid-size-mobile: 4 !default;
$grid-size-small-mobile: 3 !default;

// Lines
$line-with-border: false !default;
$line-image-height: 30px !default;

$header-direction: column !default;
$header-height: 0px !default;
$header-height-mobile: 0px !default;
$board-mobile-default-top: 220px !default;
$board-mobile-margin-top: 80px !default;
$board-mobile-translateY-offset: 160px !default;

$library-url: "" !default;

@import "../../public/assets/variables.scss";
@import "library";
@import "functions";

:export {
  primarycolor: $primary;
  controlColor: $secondary;
  secondaryjourney: $secondary-journey;
  headerDirection: $header-direction;
  headerHeight: $header-height;
  headerHeightMobile: $header-height-mobile;
  defaultBoardTop: $board-mobile-default-top;
  boardMarginTop: $board-mobile-margin-top;
  placeDiviconSize: if(variable-exists(place-divicon-size), $place-divicon-size, 0);
  errorcolor: $error;
}

#lcmap,
#lcmap-modal {
  position: relative;
  color: $text;

  font-family: $font, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif;

  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  box-sizing: content-box;

  @include desktop {
    display: flex;
    flex-direction: $header-direction;
  }

  /* Works on Firefox */
  * {
    scrollbar-width: thin;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 7px;
  }

  *::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 10px;
  }

  .lc-unsupported-warning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    padding: 15px;
    background: #808080;
    color: white;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .lc-unsuported-message {
      width: 95%;
      text-align: center;

      a {
        color: white;
      }
    }
  }

  .lc-header-row {
    // Currently styled on each override
  }

  .lc-header-column {
    display: flex;
    align-items: center;

    background-image: url(assetsPath("/assets/images/header/background.svg"));
    background-repeat: no-repeat;
    background-color: $primary;
    background-position: right;
    background-size: auto 120px;

    height: 120px;
    color: white;

    img:first-child {
      max-height: 120px;
      padding-left: 35px;
    }

    span {
      padding-left: 20px;
      font-size: 2.5em;
      font-weight: 400;
    }
  }

  .lc-header-column-mobile {
    position: sticky !important;
    top: 0;
    z-index: 9999;
    background-size: auto $header-height-mobile;
    height: $header-height-mobile;
    width: 100vw;

    span {
      display: none;
    }

    img {
      max-height: $header-height-mobile;
      padding-left: 35px;
    }
  }

  @import "admin";
  @import "board";
  @import "map";
  @import "modal";
  @import "bikes";
  @import "disruptions";
  @import "calendar";
  @import "../../public/assets/override.scss";

  @include override;

  .lc-hidden {
    display: none !important;
  }

  // Global plugins overrides
  .slider-control-bottomcenter {
    top: -23px;

    li button {
      padding: 0 2px !important;
      font-size: 16px !important;
    }
  }

  // react-collapse
  .collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  // Should be moved into react-picker-date-and-time instead
  .click-outside {
    display: inline-block;
  }

  .lc-notification {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    left: 0;

    margin: 0;
    padding: 20px;

    color: white !important;
    border-radius: 0;

    &.lc-error {
      display: flex;
      justify-content: center;

      background: #58585a;
    }

    &.lc-ios {
      top: 143px;
      right: 10px;
      left: 10px;
    }

    &::before {
      width: 15px;
      height: 15px;
      margin-right: 15px;

      content: "";

      background: url(assetsPath("/assets/images/cross-error.svg")) no-repeat center;
      background-size: 15px;
    }
  }
}

.tippy-tooltip.latitude-theme {
  background-color: $tippy-color;
  color: white;
  font-family: $font;
  @include font-x-small;
}

.tippy-tooltip.latitude-theme[data-placement^="top"] .tippy-arrow {
  border-top-color: $tippy-color;
}

.tippy-tooltip.latitude-theme[data-placement^="bottom"] .tippy-arrow {
  border-bottom-color: $tippy-color;
}

.tippy-tooltip.latitude-theme[data-placement^="left"] .tippy-arrow {
  border-left-color: $tippy-color;
}

.tippy-tooltip.latitude-theme[data-placement^="right"] .tippy-arrow {
  border-right-color: $tippy-color;
}

.rc-time-picker-panel-inner,
.rc-time-picker-panel-input {
  font-family: $font;
}
